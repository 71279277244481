import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Hyperspectral_Cover.jpg';
import metaImage from './assets/meta.jpg';

const HyperspectralPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="data-type-hyperspectral"
      helmet={{
        title: 'TileDB - Change the way you work across hyperspectral bands',
        description:
          'Slice hyperspectral cubes directly on object storage using TileDB and scale your entire analysis workflow in the cloud for massive savings.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      heroImage={BackgroundImage}
      icon={dataTypes.hyperspectral.icon}
      header="Hyperspectral imaging"
      title="Optimize workflows using analysis-ready data, and query cubes across hundreds of bands"
      overview={
        <>
          <p>
            As an earth observation scientist, your analysis often requires a subset of hyperspectral bands, but there's no clear way to
            explore and access them without downloading a massive hyperspectral dataset.
          </p>
          <p>
            Move away from HDF5 and toward a more cloud-native format that is fast and highly compressed. Multi-dimensional TileDB arrays
            simplify your workflows, letting you rapidly slice cloud-based hyperspectral cubes from your laptop, preventing large downloads.
          </p>
          <p>
            TileDB Cloud allows you to analyze data in the cloud while scaling to out-of-core computations. Interoperate with popular data
            science tools using R and Python notebooks, version your data and code and time travel for auditability, and securely manage and
            share all your data assets for collaboration with remote imaging scientists within and outside your organization.
          </p>
        </>
      }
      relatedItems={[industries.defense, industries.earthObservation, industries.oilAndGas, industries.reinsurance, dataTypes.weather]}
    />
  );
};

export default HyperspectralPage;
